import {
  Inquiry,
  InquiryElement,
  ProjectType,
  Weekday,
} from 'src/app/models/inquiry';

export function mapTypeToLabel(type: ProjectType): string {
  switch (type) {
    case 'ONCE':
      return 'Einmalige Entsorgung';
    case 'PROJECT':
      return 'Projekt-Ausschreibung';
    case 'YEARLY':
      return 'Jahres-Ausschreibung';
    case 'FRAMEWORK':
      return 'Rahmenvertrag';
    case 'framework':
      return 'Rahmenvertrag';
    default:
      throw new Error(`Unexpected ProjectType: ${type}`);
  }
}

export function mapWeekdayToGerman(weekday: Weekday): string {
  switch (weekday) {
    case 'MON':
      return 'Montag';
    case 'TUE':
      return 'Dienstag';
    case 'WED':
      return 'Mittwoch';
    case 'THU':
      return 'Donnerstag';
    case 'FRI':
      return 'Freitag';
    case 'SAT':
      return 'Samstag';
    case 'SUN':
      return 'Sonntag';
    default:
      throw new Error('Invalid weekday');
  }
}

export function flattenInquiryFormElement(element) {
  return {
    ...element.additionalInfos,
    ...element.containers,
    ...element.wasteDetails,
    ...element.wasteType,
  };
}

export function unflattenInquiryElement(element: InquiryElement) {
  return {
    id: element.id,
    containers: {
      containers: element.containers,
      containersRequired: element.containersRequired,
    },
    additionalInfos: {
      origin: element.origin,
      physicalAttributes: element.physicalAttributes,
      hpCriteria: element.hpCriteria,
      customAttributes: element.customAttributes,
      wasteImageUploads: element.wasteImageUploads,
    },
    wasteDetails: {
      individualLabel: element.individualLabel,
      quantity: element.quantity,
      unit: element.unit,
      pickup: {
        ...element.pickup,
        frequency: element.pickup.frequency || null,
        earliestDate: element.pickup.earliestDate
          ? new Date(element.pickup.earliestDate)
          : null,
        latestDate: element.pickup.latestDate
          ? new Date(element.pickup.latestDate)
          : null,
      },
    },
    wasteType: {
      aut: element.aut,
      ewc: element.ewc,
      category: element.category,
      sampleRequested: element.sampleRequested,
    },
  };
}

export function getInquiryElementById(inquiry: Inquiry, id: string) {
  if (!inquiry?.inquiryElements?.length) return null;
  return inquiry.inquiryElements.find((element) => element.id === id);
}
